<template>
	<div :class="themeClass" class="contBox margin-t-10 bg-fff" v-loading="pageLoading">
		<div class="newPersonbtn-Boxx">
			<div class="newPersonbtn-title font-size18 font-weight700 margin-t-4 padding-b-13 padding-l-13">我的发票</div>
		</div>
		<div class="newPersonbtn-Box">
			<el-form :model="addCarForm" :rules="rules" ref="addCarForm" label-width="130px" class="demo-ruleForm">
				<div class="text-center font-size16 margin-b-25">
					<span class="iconfont icon-biaodan xinzengIcon color-theme"></span>
					<span>{{pageTitle}}发票收件信息</span>
				</div>
				<el-form-item label="收件邮箱" prop="feMail">
					<el-input v-model="addCarForm.feMail" placeholder="请输入收件邮箱"></el-input>
				</el-form-item>
				<el-form-item label="收件姓名" prop="fLinkman">
					<el-input v-model="addCarForm.fLinkman" placeholder="请输入收件姓名"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="fLinkPhone">
					<el-input v-model="addCarForm.fLinkPhone" type="number" placeholder="请输入联系电话" maxlength="11"></el-input>
				</el-form-item>
				<el-form-item label="邮寄地址" prop="area">
					<div :class="{ hide: !addCarForm.area }">
						<selectArea v-model="addCarForm.area"></selectArea>
					</div>
				</el-form-item>
				<el-form-item label="详细地址" prop="fAddress">
					<el-input v-model="addCarForm.fAddress" placeholder="请输入详细地址"></el-input>


				</el-form-item>
				<el-form-item>
					<!-- <el-button class="color-theme border-theme" @click="resetForm('addCarForm')">保存</el-button> -->
					<el-button class="background-color-theme" @click="submitForm('addCarForm')">保存</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="bg-box"></div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import selectArea from '@/components/selectArea/selectArea.vue'
	export default {
		name: "SelectArea",
		props: {
			value: Array
		},
		data() {
			return {
				pageTitle: "新增发票收件信息",
				getYZ: "获取验证码",
				miao: '',
				dialogImageUrl: '',
				pageLoading:false,
				dialogVisible: false,
				addCarForm: {
					feMail: "", // 收件邮箱
					fLinkman: '', //收件姓名
					fLinkPhone: '', //手机号码
					fAddress: "", //详细地址
					area: [],
				},
				rules: {
					feMail: [{
						required: true,
						message: '请输入正确邮箱',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
						message: '请输入正确邮箱',
						trigger: 'blur'
					}],
					fLinkman: [{
						required: true,
						message: '请输入收件姓名',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^([a-zA-Z0-9\u4e00-\u9fa5\·]{1,10})$/,
						message: '您的真实姓名格式错误,请输入英文或汉字!',
						trigger: 'blur'
					}],
					fLinkPhone: [{
						required: true,
						message: '请输入正确的手机号码',
						trigger: 'blur',
					}, {
						required: true,
						pattern: /^1[34578]\d{9}$/,
						message: '请输入正确的手机号码',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请选择地址',
						trigger: 'blur',
					}],
					fAddress: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur',
					}],

				}
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {
			selectArea
		},
		mounted() {
			console.log(JSON.parse(localStorage.getItem('userAddressInform')), "sdf");

			if (this.$route.query.pageTitle == 1) {
				this.pageTitle = "新增";
				let addressInrom = JSON.parse(localStorage.getItem('userAddressInform'));
				//this.addCarForm.feMail = addressInrom.feMail;
				this.addCarForm.fLinkman = addressInrom.userNmae;
				this.addCarForm.fLinkPhone = addressInrom.userPhone;
				this.addCarForm.area = addressInrom.fFullIDPath.split('\\').splice(1, 5);
				this.addCarForm.fAddress = addressInrom.ownarea;

			} else if (this.$route.query.pageTitle == 2) {
				this.pageTitle = "变更";
				this.getReceAddress();
			}
		},
		methods: {
			//获取收件信息
			getReceAddress() {
				this.ApiRequestPostNOMess('/api/mall/eb-customer/address/get-invoice-address', {}).then(
					result => {
						console.log(result, "dizhi");
						this.addCarForm = result.obj;
						let pro = result.obj.fGovernmenAreaID.substring(0, 2);
						let cit = result.obj.fGovernmenAreaID.substring(0, 4);
						let vie = result.obj.fGovernmenAreaID.substring(0, 6);
						let are = result.obj.fGovernmenAreaID;
						this.addCarForm.area = [pro, cit, vie, are];
					},
					rej => {}
				);
			},
			//保存收件信息
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.pageLoading = true;
						if (this.pageTitle == "新增") {
							this.addCarForm.fAddressID = 0;
						}
						/* this.addCarForm.fGovernmenAreaID = this.addCarForm.area[this.addCarForm.area.length-1];
						console.log(this.addCarForm); */
						this.ApiRequestPost('/api/mall/eb-customer/address/create-invoice-address', {
							"fAddressID": this.addCarForm.fAddressID,
							"feMail": this.addCarForm.feMail,
							"fGovernmenAreaID": this.addCarForm.area[this.addCarForm.area.length - 1],
							"fAddress": this.addCarForm.fAddress,
							"fLinkman": this.addCarForm.fLinkman,
							"fLinkPhone": this.addCarForm.fLinkPhone
						}).then(
							result => {
								this.pageLoading = false;
								this.$router.push({
									path: '/PersonalContent/InvoiceInformation'
								});
							},
							rej => {this.pageLoading = false;}
						);
					} else {
						return false;
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.xinzengIcon {
		font-size: 26px;
		position: relative;
		left: 4px;
		top: 3px;
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.PersonanProfile-Number {
		font-size: 12px;
		color: #0173fe;
	}

	.aaaa {
		width: 331px;
		height: 117px;
		border-radius: 4px;
		border: 1px solid rgba(228, 228, 228, 1);
		padding: 10px 0px 0px 10px;
	}

	.contBox {
		width: 100%;
	}

	.demo-ruleForm {
		width: 462px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-title1 {
		width: 110px;
		height: 30px;
		background-color: #0173FE;
		border-radius: 2px;
		color: #FFFFFF;
		line-height: 30px;
		text-align: center;
		// float:right;
	}

	.newPersonbtn-Boxx {
		display: flex;
		justify-content: space-between;
		margin-right: 20px;
	}

	.newPersonbtn-Box {}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		width: 73px;
		height: 22px;
		color: #000000;

	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	::v-deep .el-cascader {
		width: 100%;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}

	::v-deep .el-upload--picture-card {
		width: 97px;
		height: 97px;
		line-height: 97px;
		margin: 6px;
	}

	::v-deep .el-upload-list--picture-card .el-upload-list__item {
		width: 97px;
		height: 97px;
		line-height: 97px;
		margin: 6px;
	}

	.hide {
		opacity: 0;
	}
</style>
